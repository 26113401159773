import React from 'react';
import SettingsFormControl from '../../common/components/settings/SettingsFormControl';
import MainContainer from '../../common/components/MainContainer';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import PropTypes from 'prop-types';
import ActionBar from '../../quiz/components/ActionBar';
import SettingsBox from '../../common/components/settings/SettingsBox';
import Grid from '@mui/material/Grid2';
import {Divider, Typography} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Flags from 'country-flag-icons/react/3x2'
import Box from '@mui/material/Box';
import {getAllRegions} from '../utils';
import LiveStreamsSettingsBox from './LiveStreamsSettingsBox';

export default function SettingsTab(props) {
	const model = props.model;
	const onChange = props.onChange;
	const onSubmit = props.onSubmit;
	const regions = getAllRegions();
	
	return (
		<ValidatorForm noValidate autoComplete='off' onSubmit={onSubmit} style={{marginTop: 0}}>
			<ActionBar saving={props.saving} type={'submit'}/>
			<MainContainer>
				<Grid spacing={3} container>
					<Grid container direction='column' spacing={3} size={6}>
						<Grid>
							<SettingsBox title={'Broadcast Settings'}>
								<SettingsFormControl>
									<TextValidator
										errorMessages={['Broadcast name is required']}
										fullWidth
										label='Name'
										onChange={e => onChange('name', e.target.value)}
										required
										validators={['required']}
										value={model.name}
										variant='outlined'
									/>
								</SettingsFormControl>
								
								<SettingsFormControl>
									<TextValidator
										errorMessages={['Source URL is required', 'Invalid URL']}
										fullWidth
										label='Source URL'
										onChange={e => onChange('srcUrl', e.target.value)}
										required
										validators={['required', 'matchRegexp:^https?:\\/\\/(\\w+:{0,1}\\w*@)?(\\S+)(:[0-9]+)?(\\/|\\/([\\w#!:.?+=&%@!\\-/]))?$']}
										value={model.srcUrl}
										variant='outlined'
									/>
								</SettingsFormControl>
								
								<SettingsFormControl variant='outlined'>
									<InputLabel required style={{backgroundColor: '#fff'}}>Region</InputLabel>
									<Select
										required
										value={model.region || regions[0].slug}
										onChange={e => onChange('region', e.target.value)}
									>
										{regions.map(r => {
											const FlagComponent = Flags[r.countryCode];
											return (<MenuItem key={`r${r.slug}`} value={r.slug}>
												<Box display='flex' alignItems='center'>
													<FlagComponent className={'select-flag'}/>
													{r.title}
												</Box>
											</MenuItem>)
										})}
									</Select>
								</SettingsFormControl>
								
								<SettingsFormControl variant={'outlined'}>
									<InputLabel required style={{backgroundColor: '#fff'}}>Quality</InputLabel>
									<Select
										onChange={e => onChange('quality', e.target.value)}
										required
										value={model.quality || 'high'}
									>
										<MenuItem value={'low'}>Low</MenuItem>
										<MenuItem value={'medium'}>Medium</MenuItem>
										<MenuItem value={'high'}>High</MenuItem>
									</Select>
								</SettingsFormControl>
							
							</SettingsBox>
						</Grid>
						<Grid>
							<SettingsBox title={'Broadcast Information'}>
								<Typography variant='body2' mt={0.5}>
									Your source will be streamed with the following properties:
								</Typography>
								<Typography mt={1.5}><strong>Resolution:</strong> 1280x720</Typography>
								<Typography mt={1}><strong>FPS:</strong> 30</Typography>
								<Typography mt={1}><strong>Video bitrate</strong>: 2500 Kbps</Typography>
								<Typography mt={1} mb={2}><strong>Audio bitrate</strong>: 128 Kbps</Typography>
								<Divider/>
								<Typography variant='body2' mt={2} component='div'>
									<strong>IMPORTANT:</strong>
									<ul>
										<li>It takes around 2 minutes for the server to start. Please plan live streaming accordingly.</li>
										<li>If the audio is not in sync, pause the stream server for a few seconds and then resume. This should resolve the audio issue.</li>
									</ul>
								</Typography>
							</SettingsBox>
						</Grid>
					</Grid>
					<Grid size={6}>
						<LiveStreamsSettingsBox
							channels={props.channels}
							disabled={['running', 'starting'].includes(model.status)}
							livestreams={model.livestreams}
							onChange={livestreams => props.onChange('livestreams', livestreams)}
							onError={props.onError}
						/>
					</Grid>
				</Grid>
			</MainContainer>
		
		</ValidatorForm>
	)
}

SettingsTab.propTypes = {
	model: PropTypes.object.isRequired
}
