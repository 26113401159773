export function sanitize(data) {
	const newData = JSON.parse(JSON.stringify(data));
	
	// Sanitize livestreams.
	if (newData.livestreams) {
		newData.livestreams = newData.livestreams.map(livestream => {
			if (typeof livestream.channel === 'object') {
				livestream.channel = livestream.channel._id;
			}
			return livestream;
		});
	}
	
	return newData;
}
