import React from 'react';
import SettingsBox from '../../common/components/settings/SettingsBox';
import PropTypes from 'prop-types';
import {Button} from '@mui/material';
import SettingsFormControl from '../../common/components/settings/SettingsFormControl';
import LiveStreamPicker from '../../common/components/settings/livestream/LiveStreamPicker';
import {getLiveStreamChannelType} from '../../common/utils/livestream';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';

export default function LiveStreamsSettingsBox({disabled = false, livestreams = [], ...props}) {
	
	const setLivestreams = livestreams => props.onChange(livestreams);
	
	const addLivestream = () => {
		setLivestreams([...livestreams, {streamType: 'normal', serverType: 'primary'}]);
	}
	
	const onChange = (index, livestream) => {
		const newList = [...livestreams];
		newList[index] = {...livestream};
		setLivestreams(newList);
	}
	
	const onDelete = index => {
		const newList = [...livestreams];
		newList.splice(index, 1);
		setLivestreams(newList);
	};
	
	return (
		<SettingsBox title={'Live Streams'}>
			{livestreams.map((livestream, i) => {
					const channelType = getLiveStreamChannelType(livestream, props.channels);
					const row = {...livestream};
					return <React.Fragment key={`ls${i}`}>
						<LiveStreamPicker
							channels={props.channels || []}
							disabled={props.disabled}
							livestream={livestream}
							onChange={livestream => onChange(i, {...row, ...livestream})}
							onDelete={() => onDelete(i)}
							onError={props.onError}
						/>
						
						{channelType === 'youtube' && <SettingsFormControl variant={'outlined'}>
							<InputLabel style={{backgroundColor: '#fff'}}>YouTube Server</InputLabel>
							<Select
								onChange={e => {
									row.serverType = e.target.value;
									onChange(i, row);
								}}
								required
								value={livestream.serverType}
							>
								<MenuItem value={'primary'}>Primary Server</MenuItem>
								<MenuItem value={'backup'}>Backup Server</MenuItem>
							</Select>
						</SettingsFormControl>}
						
						{channelType === 'twitch' && <SettingsFormControl variant={'outlined'}>
							<InputLabel style={{backgroundColor: '#fff'}}>Twitch Stream Type</InputLabel>
							<Select
								onChange={e => {
									row.streamType = e.target.value;
									onChange(i, row);
								}}
								required
								value={livestream.streamType}
							>
								<MenuItem value={'normal'}>Normal</MenuItem>
								<MenuItem value={'test'}>Test</MenuItem>
							</Select>
						</SettingsFormControl>}
						
						{(i < livestreams.length - 1) && <Divider flexItem className='ls-divider'/>}
					
					</React.Fragment>
				}
			)}
			<SettingsFormControl style={{alignItems: 'center'}}>
				<Button variant='contained' onClick={addLivestream} disabled={props.disabled}>Add Live Stream</Button>
			</SettingsFormControl>
		</SettingsBox>
	);
}

LiveStreamsSettingsBox.propTypes = {
	channels: PropTypes.array,
	disabled: PropTypes.bool,
	livestreams: PropTypes.array,
	onChange: PropTypes.func.isRequired,
	onError: PropTypes.func
};
