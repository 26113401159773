import React, {useEffect, useState} from 'react';
import BroadcastEditor from './components/BroadcastEditor';
import api from '../common/ApiRequest';
import PagePreloader from '../common/components/PagePreloader';
import Notification from '../common/components/Notification';
import WithChannels from '../common/providers/WithChannels';
import UserIdContext from '../common/UserIdContext';
import {useLocation, useParams} from 'react-router-dom';
import {sanitize} from './BroadcastService';

export default function BroadcastEdit() {
	
	const params = useParams();
	const location = useLocation();
	const query = new URLSearchParams(location.search);
	
	const [loadingError, setLoadingError] = useState(null);
	const [message, setMessage] = useState(query.get('created') ? 'Broadcast has been created' : null);
	const [model, setModel] = useState(null);
	const [modelVersion, setModelVersion] = useState(1);
	const [saving, setSaving] = useState(false);
	const [severity, setSeverity] = useState('success');
	const [tab] = useState(query.get('created') ? 1 : 0);
	
	useEffect(() => {
		(async () => {
			try {
				const broadcast = await api.get(`/apps/broadcast/${params.broadcastId}`);
				setModel(broadcast);
			} catch (e) {
				setLoadingError(e.toString());
			}
		})();
	}, [params.broadcastId]);
	
	const onError = error => {
		setMessage(() => error);
		setSeverity(() => 'error');
	}
	
	const onSubmit = model => {
		setSaving(true);
		
		api.post(`/apps/broadcast/${model._id}`, sanitize(model))
			.then(model => {
					setModel(model);
					setModelVersion(v => v + 1);
					setMessage('Saved');
					setSeverity('success');
				}
			)
			.catch(e => {
				setMessage(e.toString());
				setSeverity('error');
			}).finally(() => setSaving(false));
	}
	
	if (loadingError || !model) {
		return <PagePreloader error={loadingError}/>
	}
	
	return (
		<UserIdContext.Provider value={[model.user]}>
			<Notification
				message={message}
				severity={severity}
				onClear={() => setMessage(null)}
			/>
			<WithChannels userId={model.user}>
				<BroadcastEditor
					model={model}
					version={modelVersion}
					saving={saving}
					tab={tab}
					onSubmit={onSubmit}
					onError={error => onError(error.toString())}
				/>
			</WithChannels>
		</UserIdContext.Provider>
	);
}
