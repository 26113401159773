import React from 'react';
import {Routes, Route} from 'react-router-dom';
import BroadcastCreate from './BroadcastCreate';
import BroadcastEdit from './BroadcastEdit';
import BroadcastControlRoom from './BroadcastControlRoom';
import BroadcastList from './BroadcastList';
import PrivateRoute from '../common/components/PrivateRoute';
import './style.scss';

export default function BroadcastRoutes() {
	return (
		<PrivateRoute roles={['admin']} permission={'broadcast'} outputType={'children'}>
			<Routes>
				<Route path={`create`} element={<BroadcastCreate/>}/>
				<Route path={`:broadcastId`} element={<BroadcastEdit/>}/>
				<Route path={`:broadcastId/control-room`} element={<BroadcastControlRoom/>}/>
				<Route index element={<BroadcastList/>}/>
			</Routes>
		</PrivateRoute>
	)
}
